<template>
  <validation-observer ref="enteteDeFormulaires">
    <b-row>
      <FormHeader :input-data="initialsValues" @onFormSuccess="($event)=>{infos={...infos, ...$event}}"/>
      <b-col
        v-if="showEditionButtons"
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
          Fermer
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import utilsService from "@/services/utils/utils.service";
import FormHeader from '../../components/FormHeader.vue'

// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
    FormHeader
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      initialsValues:{},

      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: 'Agence de voyage', value: 'Agence de voyage' },
        { text: 'Tour Operator', value: 'Tour Operator' },
        { text: 'Agence réceptive', value: 'Agence réceptive' },
      ],
      agrements: [
        { text: 'Non', value: 'Non' },
        { text: 'Oui', value: 'Oui' },
      ],

      infos: {
        annee: '',
        mois: '',
        departement: '',
        commune: '',
        arrondissement: '',
        quartier: '',
        nomEtablissement: '',
        numIFU: '',
        nomPromoteur: '',
        nationalitePromoteur: '',
        adresseComplete: '',
        telephone1: '',
        telephone2: '',
        telephone3: '',
        typeEtablissement: '',
        agrementMinistere: '',
        referenceAgrement: '',
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        commissions: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          hebergement: null,
          locationAutomobile: null,
          primesAssurance: null,
          autresCommissions: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.annees = utilsService.getListYears();
    this.mois = utilsService.getListMonths();
    this.pays = utilsService.getListCountries();
    this.loadDepartments()
    this.loadCommunes()
    this.loadArrondissements()
    this.loadNeighborhoods();

    // console.log('⭕️', this.removeinvalidMonth(this.mois))
    // this.mois = this.removeinvalidMonth(this.mois) 
    
    // this.loadQuartier();
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
    this.initialsValues = this.customCloneDeep(this.infos)
  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.enteteDeFormulaires.validate().then(success => {
        if (success) {
          // alert('un bon début');
          if (this.infos.annee.name) this.infos.annee = this.infos.annee.name
          if (this.infos.mois.name) this.infos.mois = this.infos.mois.name
          if (this.infos.departement.name) this.infos.departement = this.infos.departement.name
          if (this.infos.commune.name) this.infos.commune = this.infos.commune.name
          if (this.infos.arrondissement.name) this.infos.arrondissement = this.infos.arrondissement.name
          if (this.infos.quartier.name) this.infos.quartier = this.infos.quartier.name
          // if (this.infos.nationalitePromoteur.name) this.infos.nationalitePromoteur = this.infos.nationalitePromoteur.name

          // if (this.infos.telephone2 == '') delete this.infos.telephone2
          // if (this.infos.telephone3 == '') delete this.infos.telephone3
          // if (this.infos.agrementMinistere == 'non') delete this.infos.referenceAgrement

          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }

          // this.$router.push({
          //   name: 'data-agence',
          //   params: { preview: true, data: this.infos },
          // })
        }
      })
    },
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => {});
    },

    loadCommunes() {
      this.action_loadCommunes(this.infos.departement)
        .then((res) => {
          this.communes = res;
        })
        .catch((err) => {});
    },
    loadArrondissements() {
      this.action_loadArrondissements(this.infos.commune)
        .then((res) => {
          this.arrondissements = res;
        })
        .catch((err) => {});
    },
    loadNeighborhoods() {
      this.action_loadNeighborhoods(this.infos.arrondissement)
        .then((res) => {
          this.quartiers = res;
        })
        .catch((err) => {});
    },
    // ---------------------------------------------------
    onFormHeaderSuccess($event){
      console.log('$event :>> ', $event);
      this.infos={...this.infos,
      annee: $event.annee,
      mois: $event.mois}
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
